import React from 'react';
import { request } from '@jvs-group/jvs-mairistem-store';
import { Form } from '@jvs-group/jvs-mairistem-composants';
import _ from 'lodash';
import { TemplateRequestGet } from '@jvs-group/jvs-mairistem-finances-utils';
import { Etiquette } from '@/src/interfaces/etiquette';

interface EtiquettesInputProps {
  // etiquette qui ne peuvent etre supprimées
  legacyEtiquettes?: Array<Etiquette>,
  selectedEtiquettes: Array<Etiquette>,
  identifiantEntite: number,
  onChange: any,
  allowAdditions: boolean,
  dropdownProps?: object
}

const EtiquettesInput = ({
  selectedEtiquettes = null,
  legacyEtiquettes = null,
  identifiantEntite = null,
  onChange = null,
  allowAdditions = true,
  dropdownProps = null,
} : EtiquettesInputProps) => {
  const [etiquettes, setEtiquettes] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const mapEtiquetteToField = (etiquette) => ({
    key: etiquette.identifiant,
    value: etiquette.identifiant,
    text: etiquette.libelle,
    'data-testid': `etiquetteOption-${etiquette.identifiant}`,
  });

  React.useEffect(() => {
    if (identifiantEntite) {
      setLoading(true);
      request.get<unknown, TemplateRequestGet<Etiquette>>(
        `/api/finances/subventionsPercues/etiquettes?identifiantEntite=${identifiantEntite}`,
      )
        .then(({ data }) => {
          setEtiquettes(data.map(mapEtiquetteToField));
        }).finally(() => {
          setLoading(false);
        });
    }
  }, [identifiantEntite]);

  const handleAddition = React.useCallback((e, { value }) => {
    request.post('/api/finances/subventionsPercues/etiquettes', {
      libelle: value,
      ...(identifiantEntite && { identifiantEntite }),
    }).then((data) => {
      setEtiquettes((state) => [...state, mapEtiquetteToField(data)]);
      onChange({ etiquettes: [...(selectedEtiquettes || []), data] });
    });
  }, [
    setEtiquettes,
    onChange,
    selectedEtiquettes,
    identifiantEntite,
  ]);
  const getEtiquettesByValues = React.useCallback((values) => {
    const elements = [];
    etiquettes?.forEach((etiquette) => {
      if (_.includes(values, etiquette.value) && !legacyEtiquettes?.some((e) => e.identifiant === etiquette.key)) {
        elements.push(
          {
            identifiant: etiquette.value,
            libelle: etiquette.text,
            identifiantEntite,
          },
        );
      }
    });

    return elements;
  }, [
    etiquettes,
    identifiantEntite,
    legacyEtiquettes,
  ]);

  const handleChange = React.useCallback((e, { value }) => {
    onChange({
      etiquettes: getEtiquettesByValues(value),
    });
  }, [getEtiquettesByValues, onChange]);

  const selectedIds = React.useMemo(() => [
    ...(legacyEtiquettes?.map((e) => e.identifiant) ?? []),
    ...(selectedEtiquettes?.map((e) => e.identifiant) ?? []),
  ], [selectedEtiquettes, legacyEtiquettes]);

  const renderLabel = (item) => ({
    content: item?.text,
    ...(legacyEtiquettes?.some((e) => e.identifiant === item?.key) && { className: 'legacy' }),
  });

  return (
    <Form.Dropdown
      options={etiquettes}
      placeholder="Etiquettes"
      search
      selection
      fluid
      multiple
      allowAdditions={allowAdditions}
      loading={loading}
      onAddItem={handleAddition}
      onChange={handleChange}
      value={selectedIds}
      noResultsMessage="Aucune étiquette trouvée"
      additionLabel="Ajouter "
      data-testid="etiquettesSubvention"
      renderLabel={renderLabel}
      {...dropdownProps}
    />
  );
};

EtiquettesInput.defaultProps = {
  legacyEtiquettes: null,
};

export default EtiquettesInput;
