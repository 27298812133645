import React from 'react';
import { Form } from '@jvs-group/jvs-mairistem-composants';
import _ from 'lodash';
import { Composant } from '@/src/interfaces/composant';
import { getAssietteEligible } from '@/src/utils/subvention';

interface ProjetComposantInputProps {
  onChange: any,
  composants: Composant[],
  selectedComposants: Partial<Composant>[],
  identifiant: number,
  plafondEligible: number,
  realisationHT: boolean,
  tauxAide: number,
}

const ProjetComposantInput = ({
  composants = null,
  onChange = null,
  selectedComposants = null,
  identifiant = null,
  plafondEligible = 0,
  realisationHT = false,
  tauxAide = 0,
} : ProjetComposantInputProps) => {
  const mapComposantToDropdown = () => composants?.map((composant) => ({
    key: composant.identifiant,
    value: composant.identifiant,
    text: composant.libelle,
    dataTestId: composant.libelle,
  }));

  const getComposantsByValues = React.useCallback((values) => {
    const elements = [];
    composants?.forEach((composant : any) => {
      if (_.includes(values, composant.identifiant)) {
        elements.push(composant);
      }
    });

    return elements;
  }, [composants]);

  const handleChange = React.useCallback((e, { value }) => {
    const composants = getComposantsByValues(value);
    const assietteEligible = getAssietteEligible(
      plafondEligible,
      composants,
      realisationHT,
    );
    onChange({
      identifiant,
      composants,
      assietteEligible,
      montantEnvisage: (assietteEligible * tauxAide) / 100,
    });
  }, [
    getComposantsByValues,
    identifiant,
    onChange,
    realisationHT,
    plafondEligible,
    tauxAide,
  ]);

  return (
    <Form.Dropdown
      options={mapComposantToDropdown()}
      placeholder="Composants"
      search
      selection
      fluid
      multiple
      renderLabel={(item) => ({
        content: item.text,
        'data-testid': item.dataTestId,
      })}
      onChange={handleChange}
      value={selectedComposants?.map((composant) => composant?.identifiant)}
      noResultsMessage="Aucun composant trouvé"
      data-testid="composantsSubvention"
      label="Composante(s) du projet associée(s)"
    />
  );
};

export default ProjetComposantInput;
