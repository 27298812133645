import React from 'react';
import {
  Checkbox,
  Table,
} from '@jvs-group/jvs-mairistem-composants';
import { isNewIdentifiant, newIdentifiant } from '@jvs-group/jvs-mairistem-tools';
import { NATURES_DOCUMENTS, type Gabarit, TYPE_GABARIT } from '@jvs-group/jvs-mairistem-finances-utils';
import { isNil } from 'lodash';
import { getLibelleTypeGabarit, getNatureFromGabarits } from '@/src/utils/piecesJointes';
import NatureCondition from '@/src/interfaces/natureCondition';

interface EtapeJustificatifProps {
  onChange: any,
  naturesConditions: NatureCondition[],
  deletedNaturesConditions: number[],
  gabaritsPj: Gabarit[],
}
const gabaritSubvention : string[] = [TYPE_GABARIT.PROJET, TYPE_GABARIT.SUBVENTION_PERCUE];
const EtapeJustificatif = ({
  naturesConditions = null,
  deletedNaturesConditions = null,
  onChange = null,
  gabaritsPj = null,
} : EtapeJustificatifProps) => {
  const naturesGabarit = getNatureFromGabarits(gabaritsPj);

  const handleChangeNatureCondition = (gabarit: any, natureCondition: NatureCondition, checked) => {
    if (checked) {
      onChange?.({
        naturesConditions: [
          ...(naturesConditions ?? []),
          {
            identifiant: newIdentifiant(),
            codeNature: gabarit.identifiant,
          },
        ],
      });
    } else {
      onChange?.({
        naturesConditions: [
          ...naturesConditions.filter((n) => n.identifiant !== natureCondition?.identifiant),
        ],
        deletedNaturesConditions: [
          ...deletedNaturesConditions,
          ...(!isNewIdentifiant(natureCondition?.identifiant) ? [natureCondition?.identifiant] : []),
        ],
      });
    }
  };

  const renderRow = (gabarit: any) => {
    const natureCondition = naturesConditions?.find((n) => n?.codeNature === gabarit?.identifiant);
    return (
      <Table.Row>
        <Table.Cell>
          {gabarit?.libelle ?? ''}
        </Table.Cell>
        <Table.Cell>{getLibelleTypeGabarit(gabarit?.types)}</Table.Cell>
        <Table.Cell>
          <Checkbox
            checked={!isNil(natureCondition)}
            onChange={(e, { checked }) => handleChangeNatureCondition(gabarit, natureCondition, checked)}
            data-testid={`natureConditionCheckbox-${gabarit?.identifiant}`}
          />
        </Table.Cell>
      </Table.Row>
    );
  };

  const getAutreNature = () => NATURES_DOCUMENTS.filter(
    (n) => gabaritSubvention.includes(n.gabarit)
    && !naturesGabarit.some((g) => g.identifiant === n.identifiant),
  ).sort((a, b) => a.libelle.localeCompare(b.libelle));

  return (
    <>
      <p>
        Sélectionnez les natures des documents à fournir lors de la demande de versement.
        <br />
        Le dossier sera complété avec l&apos;état récapitulatif des dépenses et ses factures justificatives
      </p>

      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type de document</Table.HeaderCell>
            <Table.HeaderCell>Origine</Table.HeaderCell>
            <Table.HeaderCell>A fournir</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {naturesGabarit?.map((n: any) => renderRow(n))}
          {getAutreNature()?.map((n: any) => renderRow(n))}
        </Table.Body>
      </Table>
    </>
  );
};

export default EtapeJustificatif;
