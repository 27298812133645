import React from 'react';
import '@elastic/charts/dist/theme_light.css';
import {
  Button,
  Grid, Segment, Table, Toggle,
} from '@jvs-group/jvs-mairistem-composants';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { formatMontant } from '@jvs-group/jvs-mairistem-finances-utils';
import { isNewIdentifiant } from '@jvs-group/jvs-mairistem-tools';
import PieChart from '@/src/components/ProjetFichePanel/Financement/Chart/PieChart';
import '@/src/components/ProjetFichePanel/Financement/Financement.css';
import { Projet } from '@/src/interfaces/projet';
import { getMontantProjet, getPrevisisonFinancement } from '@/src/utils/projet';
import { getMontantRealiseConditions } from '@/src/utils/conditionVersement';
import type { Subvention } from '@/src/interfaces/subvention';

const SLICE_AUTOFINANCEMENT = 'AUT';
const SLICE_FINANCEMENT_EXTERNE = 'EXT';

const CHART_LABEL = {
  [SLICE_AUTOFINANCEMENT]: 'Auto financement',
  [SLICE_FINANCEMENT_EXTERNE]: 'Financements externes',
};

const CHART_COLORS = {
  [SLICE_AUTOFINANCEMENT]: '#ED7D31',
  [SLICE_FINANCEMENT_EXTERNE]: '#5B9BD5',
};

const clamp = (value : number, min : number, max : number) : number => Math.min(Math.max(value, min), max);

interface FinancementProps {
  projet: Projet,
  onCreateSubvention: any,
}

const Financement = ({
  projet,
  onCreateSubvention,
} : FinancementProps) => {
  const [modeHT, setModeHT] = React.useState(true);
  const history = useHistory();

  const montantProjet = getMontantProjet(projet, modeHT);

  const prevision = getPrevisisonFinancement(projet, modeHT);
  const realisation = getMontantRealiseConditions(
    projet?.subventions?.map((s) => s.conditionsVersements)?.flat(),
    modeHT,
  );
  const realisationAutofinance = (montantProjet ?? 0) - realisation;

  const handleSubventionClick = (identifiant : number) => {
    history.push(`/subventionsPercues/${identifiant}`);
  };

  const handleChangeModeHT = (index) => {
    setModeHT(index === 0);
  };

  const getTaux = (subvention : Subvention) : number => {
    if (montantProjet === 0) {
      return 0;
    }
    return ((subvention.mntAttribue / montantProjet) * 100);
  };

  return (
    <>
      <div id="modeRealisationFinancement">
        <div>
          <Toggle onChange={handleChangeModeHT}>
            <Button active={modeHT} data-testid="modeHT">HT</Button>
            <Button active={!modeHT} data-testid="modeTTC">TTC</Button>
          </Toggle>
        </div>
      </div>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column className="flex" width={10}>
            <Grid className="financement-table">
              <Grid.Row columns={2} stretched>
                <Grid.Column />
                <Grid.Column className="italic" textAlign="right">
                  Prévisionnel
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column className="bold flex">
                  <div className="autofinancement legend" />
                  Autofinancement
                </Grid.Column>
                <Grid.Column className="bold" textAlign="right">
                  {formatMontant(prevision?.autofinancement)}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column className="bold flex">
                  <div className="financement_externes legend" />
                  Financements externes
                </Grid.Column>
                <Grid.Column className="bold" textAlign="right">
                  {formatMontant(prevision?.externe)}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <PieChart
              id="prevision"
              data={[
                {
                  type: SLICE_AUTOFINANCEMENT,
                  percentage: clamp((((prevision?.autofinancement ?? 0) / montantProjet) * 100), 0, 100).toFixed(2),
                },
                {
                  type: SLICE_FINANCEMENT_EXTERNE,
                  percentage: clamp((((prevision?.externe ?? 0) / montantProjet) * 100), 0, 100).toFixed(2),
                },
              ]}
              colors={CHART_COLORS}
              labels={CHART_LABEL}
            />
          </Grid.Column>

          <Grid.Column className="flex" width={6}>
            <Grid className="financement-table">
              <Grid.Row columns={2} stretched>
                <Grid.Column className="italic" textAlign="right">
                  Réel
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column className="bold" textAlign="right">
                  {formatMontant(realisationAutofinance)}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column className="bold" textAlign="right">
                  {formatMontant(realisation)}
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <PieChart
              id="actuel"
              data={[
                {
                  type: SLICE_AUTOFINANCEMENT,
                  percentage: clamp((((realisationAutofinance ?? 0) / montantProjet) * 100), 0, 100).toFixed(2),
                },
                {
                  type: SLICE_FINANCEMENT_EXTERNE,
                  percentage: clamp((((realisation ?? 0) / montantProjet) * 100), 0, 100).toFixed(2),
                },
              ]}
              colors={CHART_COLORS}
              labels={CHART_LABEL}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table
        celled
        striped
        data-testid="financement-table"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="left">Financeur</Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Libellé du dossier</Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Référence</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Montant attribué</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Taux</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {_.map(projet?.subventions, (subvention) => (
            <Table.Row onClick={() => handleSubventionClick(subvention?.identifiant)} className="pointer">
              <Table.Cell textAlign="left">
                {subvention?.financeur?.nom}
                {' '}
                {subvention?.financeur?.prenom}
              </Table.Cell>
              <Table.Cell textAlign="left">
                {subvention?.libelle}
              </Table.Cell>
              <Table.Cell textAlign="left">
                {subvention?.referenceDossier}
              </Table.Cell>
              <Table.Cell textAlign="right">
                {formatMontant(subvention?.mntAttribue)}
              </Table.Cell>
              <Table.Cell textAlign="right">
                {formatMontant(getTaux(subvention), 2, null, null, true)}
              </Table.Cell>
            </Table.Row>
          ))}

          {(projet?.subventions?.length ?? 0) === 0
            && (
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={5}>
                Aucune donnée à afficher
              </Table.Cell>
            </Table.Row>
            )}
        </Table.Body>
      </Table>

      <Segment basic>
        <Button
          floated="right"
          onClick={onCreateSubvention}
          disabled={isNewIdentifiant(projet?.identifiant)}
        >
          créer une subvention
        </Button>
      </Segment>
    </>
  );
};

export default Financement;
