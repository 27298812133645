import { ModalAssistant } from '@jvs-group/jvs-mairistem-assistant';
import React from 'react';
import { Form, SemanticCOLORS } from '@jvs-group/jvs-mairistem-composants';
import type { Gabarit } from '@jvs-group/jvs-mairistem-finances-utils';
import _ from 'lodash';
import EtapeModaliteAttribution
  from '@/src/components/SubventionFichePanel/ConditionsVersements/AssistantVersement/EtapeModaliteAttribution';
import { validateStepCaracteristique, validateStepModalite } from '@/src/utils/conditionVersement';
import EtapeCaracteristique
  from '@/src/components/SubventionFichePanel/ConditionsVersements/AssistantVersement/EtapeCaracteristique';
import { ConditionVersement } from '@/src/interfaces/conditionVersement';
import EtapeJustificatif
  from '@/src/components/SubventionFichePanel/ConditionsVersements/AssistantVersement/EtapeJustificatif';

export const STEP_CARACTERISTIQUE = 0;
export const STEP_MODALITE_ATTRIBUTION = 1;
export const STEP_JUSTIFICATIF = 2;

interface AssistantVersementsProps {
  conditionVersement: ConditionVersement,
  montantPrecedentesConditions: number,
  // eslint-disable-next-line react/forbid-prop-types
  budgetOptions: any,
  open: boolean,
  onFinish: any,
  // sert a mettre a jour le store direcement sans validation de l'utilisteur
  onClose: any,
  onLibelleDatalist: any,
  step: number,
  montantSubvention: number,
  color: SemanticCOLORS,
  gabaritsPj: Gabarit[],
}

const AssistantVersements = ({
  conditionVersement = null,
  montantPrecedentesConditions = 0,
  budgetOptions = null,
  open = false,
  onFinish = null,
  onClose = null,
  step: stepProps = null,
  montantSubvention = 0,
  onLibelleDatalist = null,
  color = null,
  gabaritsPj = null,
} : AssistantVersementsProps) => {
  const [versement, setVersement] = React.useState(conditionVersement);

  const initialStep = stepProps ?? 0;
  const [currentStep, setCurrentStep] = React.useState(initialStep);

  const [steps, setSteps] = React.useState([
    {
      title: 'Caractéristiques',
      dataTestId: 'boutonCaracteristiquesStep',
    },
    {
      title: "Modalités d'attribution",
      dataTestId: 'boutonModalitesAttributionStep',
    },
    {
      title: 'Justificatifs à fournir',
      dataTestId: 'boutonJustificatifsStep',
      required: false,
    },
  ]);

  const isNewIdentifiant = React.useCallback(
    () => _.isNil(conditionVersement?.identifiant) || _.isString(conditionVersement?.identifiant),
    [conditionVersement?.identifiant],
  );

  const initSteps = React.useCallback(() => {
    setSteps((oldSteps) => (
      [...(oldSteps?.map((step) => ({ ...step, error: null, validated: !isNewIdentifiant() })) ?? [])]
    ));
    setCurrentStep(initialStep);
  }, [initialStep, isNewIdentifiant]);

  React.useEffect(() => {
    initSteps();
  }, [initSteps, conditionVersement?.identifiant]);

  const handleSaveStep = React.useCallback((index) => {
    let stepResult = null;
    switch (index) {
      case STEP_CARACTERISTIQUE:
        stepResult = validateStepCaracteristique(versement);
        break;
      case STEP_MODALITE_ATTRIBUTION:
        stepResult = validateStepModalite(
          montantPrecedentesConditions,
          versement?.montant,
          versement?.dateButoire,
          montantSubvention,
        );
        break;
      default:
        return true;
    }

    setSteps((oldSteps) => ([
      ...(oldSteps?.map((step, i) => {
        if (i !== index) {
          return step;
        }

        return { ...step, ...stepResult };
      }) ?? []),
    ]));

    return stepResult.validated;
  }, [
    versement,
    montantPrecedentesConditions,
    montantSubvention,
  ]);

  const handleFinish = React.useCallback(() => {
    const stepCaracteristique = handleSaveStep(STEP_CARACTERISTIQUE);
    const stepAttribution = handleSaveStep(STEP_MODALITE_ATTRIBUTION);

    if (!stepCaracteristique) {
      setCurrentStep(STEP_CARACTERISTIQUE);
    }

    if (!stepAttribution) {
      setCurrentStep(STEP_MODALITE_ATTRIBUTION);
    }

    if (stepCaracteristique
            && stepAttribution
            && onFinish) {
      onFinish(versement);
    }
  }, [
    onFinish,
    versement,
    handleSaveStep,
  ]);

  const handleCurrentStepChange = (newStep) => {
    setCurrentStep(newStep);
  };

  const handleChange = React.useCallback((condition) => {
    setSteps((oldSteps) => (
      [
        ...(oldSteps?.map((step, index) => {
          if (index === currentStep) {
            return { ...step, validated: !isNewIdentifiant() };
          }

          return step;
        }) ?? []),
      ]
    ));

    setVersement((old) => ({
      ...old,
      ...condition,
    }));
  }, [currentStep, isNewIdentifiant]);

  const handleClose = () => {
    initSteps();
    if (onClose) onClose();
  };

  return (
    <ModalAssistant
      open={open}
      title="Versement"
      steps={steps}
      heightContent={window.innerHeight * 0.6}
      onFinish={handleFinish}
      onStepChange={handleCurrentStepChange}
      onSaveStep={handleSaveStep}
      onClose={handleClose}
      currentStep={currentStep}
      initialStep={STEP_CARACTERISTIQUE}
      color={color}
      data-testid="conditionVersementModal"
    >
      <ModalAssistant.Content>
        <Form>
          {currentStep === STEP_CARACTERISTIQUE
                    && (
                    <EtapeCaracteristique
                      conditionVersement={versement}
                      montantPrecedentesConditions={montantPrecedentesConditions}
                      onLibelleDatalist={onLibelleDatalist}
                      montantSubvention={montantSubvention}
                      onChange={handleChange}
                      budgetOptions={budgetOptions}
                    />
                    ) }

          {currentStep === STEP_MODALITE_ATTRIBUTION
                    && (
                    <EtapeModaliteAttribution
                      conditionVersement={versement}
                      onChange={handleChange}
                      montantPrecedentesConditions={montantPrecedentesConditions}
                      montantSubvention={montantSubvention}
                    />
                    ) }

          {currentStep === STEP_JUSTIFICATIF
                    && (
                    <EtapeJustificatif
                      naturesConditions={versement?.naturesConditions}
                      deletedNaturesConditions={versement?.deletedNaturesConditions}
                      onChange={handleChange}
                      gabaritsPj={gabaritsPj}
                    />
                    ) }
        </Form>
      </ModalAssistant.Content>
    </ModalAssistant>
  );
};

export default AssistantVersements;
