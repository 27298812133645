import * as React from 'react';

import {
  App,
  Feature,
  Menu,
  SpeedDial,
} from '@jvs-group/jvs-mairistem-module';

import { request } from '@jvs-group/jvs-mairistem-store';
import { Segment } from '@jvs-group/jvs-mairistem-composants';
import { Viewer } from '@jvs-group/jvs-mairistem-pieces-jointes';

import '@/src/index.less';
import Context from '@/src/components/Context';

import Accueil from '@/src/components/Dashboard/Dashboard';

import SubventionListe from '@/src/components/SubventionListe/SubventionListeContainer';
import SubventionFiche from '@/src/components/SubventionFiche/SubventionFiche';
import SubventionFicheBar from '@/src/components/SubventionFiche/SubventionFicheBar';

import ProjetListe from '@/src/components/ProjetListe/ProjetListeContainer';
import ProjetFiche from '@/src/components/ProjetFiche/ProjetFiche';
import ProjetFicheBar from '@/src/components/ProjetFiche/ProjetFicheBar';

import Parametres from '@/src/components/Parametres/Parametres';
import AppPlanning from '@/src/components/Planning/Planning';

const AppSubvention = () => {
  const handleOnAuth = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    sessionStorage.setItem('identifiantEntite', queryParams.get('identifiantEntite'));
  };

  return (
    <App
      name="Subventions"
      code="HOL_SUBP"
      group="finances"
      context={Context}
      onAuth={handleOnAuth}
      request={request}
    >
      <Feature path="/:path*/preview/" name="Prévisualisation">
        <Feature.Content>{Viewer}</Feature.Content>
      </Feature>
      <Menu.Home dataTestId="ongletAccueil">
        <Feature name="Accueil">
          <Feature.Content>
            <Segment inverted attached className="feature">
              <Segment>
                <Accueil />
              </Segment>
            </Segment>
          </Feature.Content>
        </Feature>
      </Menu.Home>
      <Menu.Settings dataTestId="ongletParametre">
        <Feature>
          <Feature.Content>
            <Segment inverted attached className="feature">
              <Segment>
                <Parametres />
              </Segment>
            </Segment>
          </Feature.Content>
        </Feature>
      </Menu.Settings>
      <Menu.Separator />
      <Menu name="Projets" icon="folder open" path="/projets" dataTestId="ongletProjet">
        <Feature path="/:identifiant">
          <Feature.Header>{ProjetFicheBar}</Feature.Header>
          <Feature.Content>{ProjetFiche}</Feature.Content>
        </Feature>
        <Feature>
          <Feature.Content>
            <Segment inverted attached className="feature">
              <Segment>
                <ProjetListe />
              </Segment>
            </Segment>
          </Feature.Content>
        </Feature>
      </Menu>
      <Menu name="Subventions" icon="money-bill" path="/subventionsPercues" dataTestId="ongletSubvention">
        <Feature path="/:identifiant">
          <Feature.Header>{SubventionFicheBar}</Feature.Header>
          <Feature.Content>{SubventionFiche}</Feature.Content>
        </Feature>
        <Feature>
          <Feature.Content>
            <Segment inverted attached className="feature">
              <Segment>
                <SubventionListe />
              </Segment>
            </Segment>
          </Feature.Content>
        </Feature>
      </Menu>
      <Menu name="Planning" icon="calendar" path="/planning" dataTestId="ongletPlanning">
        <Feature>
          <Feature.Content>
            <Segment inverted attached className="feature">
              <Segment>
                <AppPlanning timelineBoundaries />
              </Segment>
            </Segment>
          </Feature.Content>
        </Feature>
      </Menu>
      <SpeedDial>
        <SpeedDial.Button
          name="create_projet"
          icon="folder open"
          title="Créer un projet"
          path="/projets/_"
          dataTestId="boutonAjoutProjet"
        />
        <SpeedDial.Button
          name="create_subvention"
          icon="money-bill"
          title="Créer une subvention"
          path="/subventionsPercues/_"
          dataTestId="boutonAjoutSubvention"
        />
      </SpeedDial>
    </App>
  );
};
export default AppSubvention;
