import React from 'react';
import {
  Grid,
  Button,
  Slider,
  Currency,
  Form,
} from '@jvs-group/jvs-mairistem-composants';
import { Date } from '@jvs-group/jvs-mairistem-finances-utils';
import { ConditionVersement } from '@/src/interfaces/conditionVersement';

const STEP_DEBUT = 0;
const STEP_EN_COURS = 1;
const STEP_FIN = 2;

interface EtapeModaliteAttributionProps {
  conditionVersement: ConditionVersement,
  onChange: any,
  montantPrecedentesConditions: number,
  montantSubvention: number,
}

const EtapeModaliteAttribution = ({
  conditionVersement = null,
  onChange = null,
  montantPrecedentesConditions = 0,
  montantSubvention = 0,
} : EtapeModaliteAttributionProps) => {
  const [currentStep, setCurrentStep] = React.useState(STEP_DEBUT);
  const isSolde = montantPrecedentesConditions + (conditionVersement?.montant ?? 0) >= montantSubvention;

  const getCurrentStep = React.useCallback(() => {
    if (conditionVersement?.pourcentageExecution === 0) {
      return STEP_DEBUT;
    } if (conditionVersement?.pourcentageExecution === 100) {
      return STEP_FIN;
    }

    return STEP_EN_COURS;
  }, [conditionVersement?.pourcentageExecution]);

  React.useEffect(() => {
    setCurrentStep(getCurrentStep());
  }, [getCurrentStep]);

  const setPourcentage = (percent) => {
    onChange({
      pourcentageExecution: Math.min(Math.max(percent, 0), 100),
    });
  };

  const onSliderChange = ({ target: { value } }) => {
    setPourcentage(parseInt(value, 10));
  };

  const handleChangePourcentage = (e, value) => {
    setPourcentage(parseInt(value, 10));
  };

  const handleChangeDate = (value: string, name: string) => onChange?.({ [name]: value });

  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column>
          <Button
            fluid
            onClick={() => setPourcentage(0)}
            {...(currentStep === STEP_DEBUT) ? { primary: true } : { basic: true }}
            data-testid="modaliteDebutCondition"
          >
            Au démarrage du projet
          </Button>
        </Grid.Column>

        <Grid.Column>
          <Button
            fluid
            onClick={() => setPourcentage(50)}
            {...(currentStep === STEP_EN_COURS) ? { primary: true } : { basic: true }}
            data-testid="modaliteEnCoursCondition"
          >
            {' '}
            En cours de réalisation du projet
          </Button>
        </Grid.Column>

        <Grid.Column>
          <Button
            fluid
            onClick={() => setPourcentage(100)}
            {...(currentStep === STEP_FIN) ? { primary: true } : { basic: true }}
            data-testid="modaliteFinCondition"
          >
            Lorsque le projet est fini
          </Button>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16} className="inline flex">
          <Slider
            onInput={onSliderChange}
            value={conditionVersement?.pourcentageExecution}
            step={5}
            // @ts-expect-error
            className="flex-1"
            fluid
          />
          <Currency
            key="pourcentageExecution"
            name="pourcentageExecution"
            className="percent small"
            value={conditionVersement?.pourcentageExecution}
            percent
            onChange={handleChangePourcentage}
          >
            <input data-testid="pourcentageExecutionInput" />
          </Currency>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={3}>
          <Form.Field
            control={Date}
            data-testid="dateButoireCondition"
            key="dateButoire"
            label="Date butoir"
            // @ts-expect-error
            name="dateButoire"
            onChange={handleChangeDate}
            required={isSolde}
            value={conditionVersement?.dateButoire}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={3}>
          <Form.Field
            control={Date}
            data-testid="dateAlerteCondition"
            key="dateAlerte"
            label="Alerter avant"
            // @ts-expect-error
            name="dateAlerte"
            onChange={handleChangeDate}
            value={conditionVersement?.dateAlerte}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default EtapeModaliteAttribution;
