// #region License

/**
 * @license
 * Copyright (C) JVS-Mairistem
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 *
 * Proprietary and confidential
 */

// #endregion

import * as Sentry from '@sentry/react';

import { isProduction } from '@jvs-group/jvs-mairistem-tools';

import { getEnv } from '@jvs-group/jvs-mairistem-finances-utils';
import pkg from '@/package.json';

export const initialize = () => {
  if (isProduction()) {
    Sentry.init({
      dsn: 'https://fd3d640003a4c1ea9315aa85c8f803cd@sentry.jvs-mairistem.dev/6',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration({
          maskAllText: true,
          blockAllMedia: true,
        }),
      ],
      tracesSampleRate: 1.0,
      profilesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      release:
        getEnv() === 'development'
          ? `${pkg.name.replace(/^@[\w-]+(\.)?[\w-]+\//, '')}@dev`
          : `${pkg.name.replace(/^@[\w-]+(\.)?[\w-]+\//, '')}@${pkg.version}`,
      environment: getEnv(),
    });
  }
};

export default isProduction() ? Sentry : null;
