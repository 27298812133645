import React from 'react';
import { Form } from '@jvs-group/jvs-mairistem-composants';
import _ from 'lodash';
import { isNewIdentifiant, newIdentifiant } from '@jvs-group/jvs-mairistem-tools';
import { request } from '@jvs-group/jvs-mairistem-store';
import { UtilisateurProjet } from '@/src/interfaces/utilisateurProjet';

interface UtilisateurProjetInputProps {
  onChange: any,
  utilisateursProjet: UtilisateurProjet[],
  identifiant: number,
  deletedUtilisateursProjet: number[],
}

const UtilisateurProjetInput = ({
  onChange = null,
  utilisateursProjet = null,
  deletedUtilisateursProjet = null,
  identifiant = null,
} : UtilisateurProjetInputProps) => {
  const [utilisateurs, setUtilisateurs] = React.useState([]);

  React.useEffect(() => {
    request.get('/api/transverse/utilisateurs/?all=true&actif=true').then(
      ({ data }) => setUtilisateurs(data.map((utilisateur) => {
        const textData = [
          `${utilisateur.nom ?? ''} ${utilisateur.prenom ?? ''}`,
          ...(utilisateur.email ? [utilisateur.email] : []),
          ...(utilisateur.portable ? [utilisateur.portable] : []),
        ];
        return {
          key: utilisateur.identifiant,
          value: utilisateur.identifiant,
          // eslint-disable-next-line max-len
          text: `${utilisateur.nom ?? ''} ${utilisateur.prenom ?? ''}`,
          fullText: `${textData.join(' - ')}`,
          'data-testid': `optionUtilisateur${utilisateur.nom}`,
        };
      })),
    );
  }, []);

  const getUtilisateursByValues = React.useCallback((values) => {
    const elements = [];
    utilisateurs?.forEach((utilisateur : any) => {
      if (_.includes(values, utilisateur.value)) {
        elements.push({
          identifiantLogin: utilisateur?.value,
          identifiant: utilisateursProjet?.find(
            (up) => up.identifiantLogin === utilisateur.value,
          )?.identifiant ?? newIdentifiant(),
        });
      }
    });

    return elements;
  }, [utilisateurs, utilisateursProjet]);

  const handleChange = React.useCallback((e, { value }) => {
    const newUtilisateursProjet = getUtilisateursByValues(value);
    const deletedUtilisateurs = utilisateursProjet?.filter(
      (utilisateur) => !newUtilisateursProjet?.find((nu) => nu.identifiant === utilisateur.identifiant)
        && !isNewIdentifiant(utilisateur.identifiant),
    )?.map((utilisateur) => utilisateur?.identifiant);

    onChange({
      identifiant,
      utilisateursProjet: newUtilisateursProjet,
      deletedUtilisateursProjet: [
        ...(deletedUtilisateursProjet ?? []),
        ...deletedUtilisateurs,
      ],
    });
  }, [
    getUtilisateursByValues,
    identifiant,
    deletedUtilisateursProjet,
    onChange,
    utilisateursProjet,
  ]);

  const renderLabel = (item) => item?.fullText;

  return (
    <Form.Dropdown
      options={utilisateurs ?? []}
      label="Agent référent"
      search
      selection
      fluid
      multiple
      onChange={handleChange}
      value={utilisateursProjet?.map((utilisateur) => utilisateur?.identifiantLogin) ?? []}
      noResultsMessage="Aucun agent trouvé"
      data-testid="utilisateursSubventionDropdown"
      renderLabel={renderLabel}
    />
  );
};

export default UtilisateurProjetInput;
