export enum ProjetStatus {
    CONCEPTION = 1,
    PLANIFICATION = 2,
    EXECUTION = 3,
    CLOTURE = 4,
}

export const PROJET_STATUS_OPTIONS = [
  {
    key: ProjetStatus.CONCEPTION,
    value: ProjetStatus.CONCEPTION,
    text: 'Conception',
    'data-testid': 'statusProjetOptionConception',
  },
  {
    key: ProjetStatus.PLANIFICATION,
    value: ProjetStatus.PLANIFICATION,
    text: 'Planification',
    'data-testid': 'statusProjetOptionPlanification',
  },
  {
    key: ProjetStatus.EXECUTION,
    value: ProjetStatus.EXECUTION,
    text: 'Exécution',
    'data-testid': 'statusProjetOptionExecution',
  },
  {
    key: ProjetStatus.CLOTURE,
    value: ProjetStatus.CLOTURE,
    text: 'Clôturé',
    'data-testid': 'statusProjetOptionCloture',
  },
];
