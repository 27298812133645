import React, { useState } from 'react';
import {
  Planning,
  CalendarView,
  ResourceContainer,
  ResourceColumn,
  Event,
  ViewSelection,
  TimelineControl,
  type EventType,
  Hover,
} from '@jvs-group/jvs-mairistem-planning';
import dayjs, { type Dayjs } from 'dayjs';
import { request } from '@jvs-group/jvs-mairistem-store';
import { Link } from 'react-router-dom';
import { formatMontant, type TemplateRequestGet } from '@jvs-group/jvs-mairistem-finances-utils';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Popup } from '@jvs-group/jvs-mairistem-composants';
import type { Projet } from '@/src/interfaces/projet';
import Avatar from '@/src/components/Common/Avatar';
import PlanningSearchPanel from '@/src/components/Planning/PlanningSearchPanel';
import '@/src/components/Planning/planning.less';
import PlanningLegend from '@/src/components/Planning/PlanningLegend';

interface Props {
  timelineBoundaries: boolean;
  onHeaderClick?: (d: Dayjs) => void;
}

interface ResourceType {
  identifiant: number;
  name: string;
}

const ROW_HEIGHT = 65;

const AppPlanning = (props: Props) => {
  const {
    timelineBoundaries,
    onHeaderClick,
  } = props;
  const [view, setView] = useState('month');
  const [projects, setProjects] = useState<Array<EventType>>([]);
  const [resources, setResources] = useState<ResourceType[]>([]);
  const [selectedEtiquettes, setSelectedEtiquettes] = useState([]);
  const [search, setSearch] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isMandat, setIsMandat] = useState(false);
  const customRowHeight = true;
  const identifiantEntite = (Number(sessionStorage.getItem('identifiantEntite')));

  enum ProjectStatus {
    BLUE = 1,
    PINK = 2,
    GREEN = 3,
    GREY = 4,
  }

  const getColorForProject = (status: ProjectStatus): string => {
    switch (status) {
      case ProjectStatus.PINK:
        return 'pink';
      case ProjectStatus.GREEN:
        return 'green';
      case ProjectStatus.GREY:
        return 'grey';
      case ProjectStatus.BLUE: default:
        return 'blue';
    }
  };

  const generateYearOptions = (startYear, endYear) => {
    const options = [];

    for (let year = startYear; year <= endYear; year++) {
      options.push({ key: year, text: year.toString(), value: year });
    }

    return options;
  };

  const currentYear = new Date().getFullYear();
  const yearOptions = generateYearOptions(currentYear - 2, currentYear + 1);

  const generateMandatOptions = () => {
    const currentYear = new Date().getFullYear();
    const mandatStartYear = 2020 + 6 * Math.floor((currentYear - 2020) / 6); // Année de début du mandat
    const mandatDuration = 6; // Durée du mandat en années
    const numberOfMandats = 4; // Nombre total de mandats à afficher

    const options = [];

    for (let i = -1; i < numberOfMandats - 1; i++) {
      const mandatStart = mandatStartYear + (i * mandatDuration);
      const mandatEnd = mandatStart + mandatDuration;

      if (mandatStart <= currentYear || i > 0) {
        options.push({
          key: `mandat-${mandatStart}-${mandatEnd}`,
          text: `${mandatStart} - ${mandatEnd}`,
          value: `${mandatStart} - ${mandatEnd}`,
        });
      }
    }

    return options;
  };
  const mandatOptions = generateMandatOptions();

  const getRowHeight = () => ROW_HEIGHT; // Taille des lignes

  const extractResources = (projects) => {
    const uniqueResources = Array.from(new Set(projects.map((project) => project.identifiantResource)));

    const mappedResources = uniqueResources.map((identifiantResource) => {
      const associatedProjects = projects.filter((project) => project.identifiantResource === identifiantResource);
      return {
        identifiant: Number(identifiantResource),
        name: associatedProjects.length > 0 ? associatedProjects[0].name : '',
      };
    });

    return mappedResources;
  };

  const handleEtiquetteChange = ({ etiquettes }) => {
    setSelectedEtiquettes(etiquettes);
  };

  const handleSearch = (query: string) => {
    setSearch(query);
  };

  const handleYearChange = (newYear) => {
    setSelectedYear(newYear);
  };

  const handleHeaderClick = React.useCallback((e: React.MouseEvent, d: Dayjs) => {
    onHeaderClick(d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // permet au numéro de jour d'être dans la meme cellule que la lettre désignant un jour de la semaine.

  const fetchData = async (isSearch = false) => {
    let url = '/api/finances/subventionsPercues/projets';

    const params = new URLSearchParams();

    if (isSearch) {
      url += '/search';

      if (!_.isEmpty(search)) {
        params.append('q', search);
      }
      if (!_.isEmpty(selectedEtiquettes)) {
        selectedEtiquettes.forEach((etiquette) => {
          params.append('etiquettesId[]', etiquette.identifiant);
        });
      }
    }

    if (isMandat) {
      params.append('startDate', `${selectedYear}-01-01`);
      params.append('endDate', `${selectedYear + 6}-12-31`);
    } else {
      params.append('startDate', `${selectedYear}-01-01`);
      params.append('endDate', `${selectedYear}-12-31`);
    }

    params.append('all', 'true');
    params.append('withComposants', 'true');

    try {
      const response = await request.get<unknown, TemplateRequestGet<Projet>>(
        `${url}?${params.toString()}`,
      );

      const { data } = response;

      const apiProjects: EventType[] = data.map((item) => ({
        identifiant: item.identifiant,
        name: item.libelle,
        dateDebut: dayjs(item.dateExecution),
        dateFin: dayjs(item.dateFin),
        status: item.status,
        composants: item.composants,
        montantHT: item.montantHT,
        identifiantResource: item.identifiant,
        identifiantDocInfo: item.identifiantDocInfo,
      }));

      setProjects(apiProjects);

      const extractedResources = extractResources(apiProjects);
      setResources(extractedResources);
    } catch {
      toast.error('Erreur lors de la récuperation des projets');
    }
  };

  // Permet de chercher les projets par rapport aux filtres
  const isSearchActive = () => !_.isEmpty(search) || !_.isEmpty(selectedEtiquettes);

  React.useEffect(() => {
    if (isSearchActive()) {
      fetchData(true);
    } else {
      fetchData(); // Pas de recherche active, réinitialisation de l'affichage
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEtiquettes, search, selectedYear, isMandat]);

  return (
    <>
      <PlanningSearchPanel
        onSearch={handleSearch}
        identifiantEntite={identifiantEntite}
        onChange={handleEtiquetteChange}
        selectedEtiquettes={selectedEtiquettes}
        yearOptions={yearOptions}
        mandatOptions={mandatOptions}
        onYearChange={handleYearChange}
        isMandat={isMandat}
        setIsMandat={setIsMandat}
      />
      <div className="planningContainer">
        <Planning
          events={projects}
          view={view}
          hover={Hover.NONE}
          innerscroll
          {...customRowHeight && { getRowHeight }}
          onHeaderClick={handleHeaderClick}
        >
          <ResourceContainer
            resources={resources}
            headers={['Nom']}
            {...customRowHeight && { getRowHeight }}
          >
            <ResourceColumn>
              {(resource : ResourceType) => (
                <Link
                  key={resource.identifiant}
                  to={`/projets/${resource.identifiant}`}
                  className="noStyleLink"
                >
                  {resource.name}
                </Link>
              )}
            </ResourceColumn>
          </ResourceContainer>
          <div className="timelineControl">
            <TimelineControl
              {...timelineBoundaries && { minDate: dayjs().subtract(50, 'year'), maxDate: dayjs().add(50, 'year') }}
            />
            <ViewSelection
              hasYearOption
              onChange={(e, { value }) => setView(value as string)}
              value={view}
            />
          </div>
          <CalendarView>
            {(project) => (
              <Event
                key={project.identifiant}
                color={getColorForProject(project.status)}
                inverted
              >
                <Link to={`/projets/${project.identifiantResource}`} className="noStyleLink">

                  <Popup
                    hoverable
                    hideOnScroll
                    trigger={(
                      <div style={{ width: '100%', height: '100%' }} />
                  )}
                    style={
                    {
                      borderRadius: '0',
                      backgroundColor: '#F3EFEF',
                      borderStyle: 'dashed',
                      borderColor: 'black',
                    }
                  }
                  >
                    <div className="popupContainer">
                      <div className="popupText">
                        <div className="bold">{project.name}</div>
                        <div className="popupComposants">
                          {project.composants.map((composant) => (
                            <div key={composant.libelle}>
                              {composant.libelle}
                            </div>
                          ))}
                        </div>
                        <div>
                          {formatMontant(project.montantHT)}
                          {' '}
                          {project.status === 1 ? 'HT (montant estimé)' : 'HT (montant actualisé)'}

                        </div>
                      </div>
                      <div>
                        <div className="popupAvatar">
                          {project.identifiantDocInfo && (
                          <Avatar
                            identifiant={project?.identifiantResource}
                            identifiantDocInfo={project?.identifiantDocInfo}
                          />
                          )}
                        </div>
                      </div>
                    </div>
                  </Popup>
                </Link>
              </Event>
            ) }
          </CalendarView>
          <PlanningLegend />
        </Planning>
      </div>
    </>
  );
};

export default AppPlanning;
